import React from 'react';
import { LoloAdmin, LoloResource } from '@lolocompany/react-admin-lolo';
import { Route } from 'react-router-dom';
import Layout from './Layout';
import MibScriptField from './MibScriptField';
import CreateMib from './CreateMib';
import Graph from './Graph';
import ListDeviceTypes from './device-type/ListDeviceTypes';
import DebugDevice from './device-debug/DeviceDebug';
import ListMetrics from './metric/ListMetrics';
import DiffMetrics from './diff/DiffMetrics';
import telia from './telia';
import { withId } from './schema_transforms';

import DeviceTypesIcon from '@material-ui/icons/FolderOpenOutlined';
import DevicesIcon from '@material-ui/icons/RouterOutlined';
import MetricsIcon from '@material-ui/icons/TimelineOutlined';
import MibsIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ScheduleIcon from '@material-ui/icons/AccessTimeOutlined';
import AlertsIcon from '@material-ui/icons/NotificationsOutlined';

import './App.css';
import TriggerMetrics from './trigger-metrics/TriggerMetrics';

const customRoutes = [
  <Route exact path="/graph" component={Graph} />,
  <Route exact path="/diff" component={DiffMetrics} />,
  <Route exact path="/device-debug" component={DebugDevice} />,
  <Route exact path="/trigger-metrics" component={TriggerMetrics} />,
];

const adminProps = {};

const { _env_ = {} } = window;
const bengzhanUrl =
  _env_.REACT_APP_BENGZHAN_URL || 'admin.bengzhan.example.com';

const App = () => {
  let apiUrl =
    _env_.REACT_APP_BENGZHAN_API_URL || 'https://api.bengzhan.example.com/api';

  switch (window.location.hostname) {
    case "localhost":
      apiUrl = "https://api.bengzhan.teliacompany.net/api"; //'https://api.bengzhan-dev.teliacompany.net/api'
      adminProps.accountsUrl = apiUrl + "/accounts/all";
      adminProps.loginPage = telia.LoginPage;
      adminProps.authProvider = telia.authProvider(apiUrl);
      adminProps.dataProvider = telia.dataProvider(apiUrl);
      break;

    case bengzhanUrl:
      apiUrl =
        _env_.REACT_APP_BENGZHAN_API_URL ||
        "https://api.bengzhan.example.com/api";
      adminProps.accountsUrl = apiUrl + "/accounts/all";
      adminProps.loginPage = telia.LoginPage;
      adminProps.authProvider = telia.authProvider(apiUrl);
      adminProps.dataProvider = telia.dataProvider(apiUrl);
      break;

    case "snmp-admin.lolo.company":
      adminProps.apiUrl =
        "https://dev.pvpc.lolo.company/grc5MWb9tiEwZAAhkHnEyP";
      break;

    case "snmp-admin.pvpc.io":
      adminProps.apiUrl = "https://be.dev.pvpc.io/grc5MWb9tiEwZAAhkHnEyP";
      break;

    default:
      adminProps.apiUrl = "http://localhost:4001";
      break;
  }

  if (process.env.REACT_APP_API_URL) {
    adminProps.apiUrl = process.env.REACT_APP_API_URL;
  }

  return (
    <LoloAdmin
      title="Lolo SNMP"
      layout={Layout}
      customRoutes={customRoutes}
      fields={{ MibScript: MibScriptField }}
      {...adminProps}
    >
      <LoloResource
        name="device-types"
        options={{ label: 'Device-Types' }}
        icon={DeviceTypesIcon}
        list={ListDeviceTypes}
      />
      <LoloResource
        name="devices"
        options={{ label: 'Devices' }}
        icon={DevicesIcon}
        {...withId('Hostname')}
      />
      <LoloResource
        name="metrics"
        options={{ label: 'Metrics' }}
        icon={MetricsIcon}
        list={ListMetrics}
        {...withId('Metric ID')}
      />
      <LoloResource
        name="schedules"
        options={{ label: 'Schedule' }}
        icon={ScheduleIcon}
      />
      <LoloResource
        name="mibs"
        options={{ label: 'MIBs' }}
        create={CreateMib}
        icon={MibsIcon}
      />
      <LoloResource
        name="alerts"
        options={{ label: 'Alerts' }}
        create={false}
        edit={false}
        icon={AlertsIcon}
      />
      <LoloResource
        name="ap-configs"
        options={{ label: 'AP: Global Config' }}
      />
      <LoloResource name="ap-dns-configs" options={{ label: 'AP: DNS' }} />
      <LoloResource
        name="ap-domain-configs"
        options={{ label: 'AP: Domains' }}
      />
      <LoloResource
        name="ap-domainrules-configs"
        options={{ label: 'AP: Domain Rules' }}
      />
      <LoloResource
        name="ap-devicetypemappings-configs"
        options={{ label: 'AP: DeviceType Mappings' }}
      />
      <LoloResource
        name="ap-snmpcommunity-configs"
        options={{ label: 'AP: SNMP Community' }}
      />
      <LoloResource
        name="ap-snmpv3auth-configs"
        options={{ label: 'AP: SNMPv3 Auth' }}
      />
      <LoloResource
        name="ap-snmpoid-configs"
        options={{ label: 'AP: SNMP OIDs' }}
      />
    </LoloAdmin>
  );
};

export default App;
