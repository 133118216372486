//@ts-check
import React from 'react';
import Button from '@material-ui/core/Button';

/**
 *
 * @param {{
 *  onClickStart: () => void;
 *  isRunning: boolean;
 *  canStart: boolean;
 * }} props
 * @returns
 */
const StartButton = ({ onClickStart, isRunning, canStart }) => {
  return (
    <Button
      fullWidth
      color="primary"
      variant="outlined"
      onClick={onClickStart}
      disabled={isRunning || !canStart}
    >
      {isRunning ? "Running..." : "Start"}
    </Button>
  );
};

export default StartButton;
