export const safelyGetRefData = ({ refData }) => {
  const keys = Object.keys(refData);
  if (keys.length === 0) {
    return {};
  }
  if (typeof refData[keys[0]] === 'object') {
    return refData;
  }
  // For legacy ref data structures
  return {
    '*': refData,
  };
};
