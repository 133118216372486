//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const SiaStatisticsStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'Compare SIA data to all devices of the same device type',
      action: 'sia-stats',
      data: {},
      onResponse: (data, log) => {
        log.info(`Device type: "${data.deviceType}"`);
        log.info('✅ == Property is present on this device');
        log.info('❌ == Property is NOT present on this device');
        log.info(
          'xx.xx% == Percentage of devices of this device type where this property is present'
        );
        log.info('-------------');
        for (const key of Object.keys(data.stats)) {
          const odds = (data.stats[key] * 100).toPrecision(4);
          log.info(
            `${
              data.deviceKeys.indexOf(key) > -1 ? '✅' : '❌'
            } "${key}" = ${odds}%`
          );
        }
      },
    });
  }
);

export default SiaStatisticsStep;
