//@ts-check
import React, { forwardRef, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import useLocalStorage from "use-local-storage";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import { useActionStep } from "../hooks/useActionStep";

const useStyles = makeStyles((theme) => ({
  controlForm: {
    display: "flex",
    flexWrap: "wrap",
    justifyItems: "center",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  chipArray: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  autocompleteOption: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

/**
 * @typedef {{
 *   mib: string;
 *   name: string;
 *   oid: string;
 *  }} OID
 *
 * @returns {OID[]}
 */
const getInitialOids = () => [];

/**
 * @returns {OID[]}
 */
const getInitialSearchableOids = () => [];

/**
 * @returns {OID[]}
 */
const getInitialSelectedOids = () => [];

/**
 * @returns {string}
 */
const getInitialSelectedMib = () => "";

/**
 * @returns {string[]}
 */
const getInitialKnownMibs = () => [];

/**
 * @returns {Record<string, OID>}
 */
const getInitialKnownOid = () => ({});

const CustomSnmpPollStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    const classes = useStyles();
    const [oids, setOids] = useLocalStorage(
      "debug-device-custom-snmp-poll",
      getInitialOids()
    );

    const [getAppendZero, setAppendZero] = useState(false);
    const [selectedMib, setSelectedMib] = useState(getInitialSelectedMib());
    const [selectedOids, setSelectedOids] = useState(getInitialSelectedOids());
    const [knownMibs, setKnownMibs] = useState(getInitialKnownMibs());
    const [knownOids, setKnownOids] = useState(getInitialKnownOid());
    const [searchableOids, setSearchableOids] = useState(
      getInitialSearchableOids()
    );
    const dataProvider = useDataProvider();

    useEffect(() => {
      (async () => {
        const resp = await dataProvider.sendRequest("/mibs?limit=100000000");
        setKnownOids(
          resp.data.mibs.reduce((acc, mib) => {
            for (const entry of mib.entries) {
              if (!entry.oid) {
                continue;
              }
              if (entry.tableName) {
                for (const col of entry.tableColumns) {
                  const colOid = `${entry.oid}.${col.number}`;
                  acc[colOid] = {
                    mib: mib.name,
                    name: `${entry.name}.${col.name}`,
                    oid: colOid,
                  };
                }
              } else {
                acc[entry.oid] = {
                  mib: mib.name,
                  name: entry.name,
                  oid: entry.oid,
                };
              }
            }
            return acc;
          }, {})
        );
      })();
    }, [dataProvider]);

    useEffect(() => {
      const knownMibs = [
        ...new Set(Object.values(knownOids).map((it) => it.mib)),
      ];
      setKnownMibs(knownMibs);
      const ifMib = knownMibs.find((mib) => mib === "IF-MIB");
      if (ifMib) {
        setSelectedMib(ifMib);
      }
    }, [knownOids]);

    useEffect(() => {
      setSearchableOids(
        Object.values(knownOids)
          .filter((it) => it.mib === selectedMib)
          .map((it) => ({
            ...it,
            oid: it.oid,
          }))
      );
    }, [knownOids, selectedMib]);

    /**
     * @param {string} oid
     * @returns {string}
     */
    const resolveOidName = (oid) => {
      if (knownOids[oid]) {
        return `${knownOids[oid].mib}::${knownOids[oid].name}`;
      }

      const parts = oid.split(".");
      for (let i = parts.length - 1; i > 0; i--) {
        const oid = parts.slice(0, i).join(".");
        if (knownOids[oid]) {
          const extra = parts.slice(i).join(".");
          return `${knownOids[oid].mib}::${knownOids[oid].name}.${extra}`;
        }
      }

      return oid;
    };

    return useActionStep(ref, {
      onFail,
      onSuccess,
      name: "Custom SNMP poll",
      information:
        "If the query fails, you can try to remove and readd the oids with the append zero option.",
      action: "poll-snmp",
      data: {
        oids: oids.map((it) => it.oid),
      },
      onResponse: (data, log) => {
        log.info(`Using SNMP auth ${data.version}`);

        let errors = 0;

        for (const varbind of data.values) {
          if ("error" in varbind) {
            log.fatal(varbind.error);
            errors += 1;
          } else {
            log.info(
              `${resolveOidName(varbind.oid)} = ${JSON.stringify(
                varbind.value
              )}`
            );
          }
        }

        return data.values.length > 0 && errors === 0;
      },
      children: [
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {oids.map(({ mib, name, oid }) => (
                <Chip
                  key={oid}
                  label={`${mib}::${name}`}
                  onDelete={() =>
                    setOids((oids) => oids?.filter((it) => it.oid !== oid))
                  }
                  className={classes.chip}
                />
              ))}
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                size="small"
                options={knownMibs}
                value={selectedMib}
                disabled={knownMibs.length === 0}
                onChange={(_, mib) => mib && setSelectedMib(mib)}
                renderInput={(params) => (
                  <TextField {...params} label="MIB" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                size="small"
                options={searchableOids.filter(
                  (it) => oids.findIndex((val) => val.oid === it.oid) === -1
                )}
                getOptionLabel={(option) => option.name}
                groupBy={(option) => option.mib}
                multiple={true}
                disabled={searchableOids.length === 0}
                value={selectedOids}
                onChange={(_, oids) => setSelectedOids(oids)}
                renderInput={(params) => (
                  <TextField {...params} label="OIDs" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={getAppendZero}
                    onChange={(_, checked) => setAppendZero(checked)}
                  />
                }
                label="Append .0 to OID"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                disabled={selectedOids.length === 0}
                onClick={() => {
                  setOids((oids) => [
                    ...selectedOids.map((it) => ({
                      mib: it.mib,
                      name: it.name,
                      oid: getAppendZero ? `${it.oid}.0` : it.oid,
                    })),
                    ...(oids || []),
                  ]);
                  setSelectedOids([]);
                }}
              >
                Add OID
              </Button>
            </Grid>
          </Grid>
        </form>,
      ],
    });
  }
);

export default CustomSnmpPollStep;
