import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';

/**
 * @typedef {'check-onboarded' | 'lookup-dns' | 'poll-snmp' | 'sia-data' | 'check-ap-logs' | 'sia-stats' | 'check-ext-connections' | 'check-ext-customer'} Action
 *
 * @param {Action} action
 * @param {{
 *  host: string,
 *  auth?: {
 *    version: AuthVersion,
 *    community?: string,
 *    username?: string,
 *    authenticationKey?: string,
 *    authenticationProtocol?: string,
 *    privacyKey?: string,
 *    privacyProtocol?: string,
 *    timeout?: number,
 *    retries?: number,
 *    port?: number,
 //*    concurrency?: number,
 //*    maxGetSize?: number,
 *    maxGetBulkSize?: number,
 *  },
 *  oids?: string[],
 * }} body
 *
 * @returns {() => Promise<{ error?: string, data?: any }>}
 */
export const useRequest = (action, body) => {
  const dataProvider = useDataProvider();

  return useCallback(async () => {
    try {
      const resp = await dataProvider.sendRequest('/debug-device', {
        method: 'POST',
        body: JSON.stringify({
          ...body,
          action: action,
        }),
      });

      if (!resp.data) {
        return {
          error: 'Request to /debug-device failed',
        };
      } else if (resp.data.error) {
        return {
          error: `Request to /debug-device failed: ${resp.data.error}`,
        };
      }
      return {
        data: resp.data,
      };
    } catch (err) {
      return {
        error: `Request to /debug-device failed: ${err.stack || err}`,
      };
    }
  }, [action, body, dataProvider]);
};
