//@ts-check
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";

import { ConfigContext } from '../device-debug/DeviceDebug';
import { AuthProtocol, AuthVersion, PrivProtocol } from './enums';
import StartButton from './StartButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  controlForm: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
      //marginTop: theme.spacing(1),
    },
  },
}));

/**
 *
 * @param {{
 *  onChangeConfig: (config: ReturnType<typeof import('./DeviceDebug').getInitialContext>) => void
 *  onClickStart: () => void;
 *  isRunning: boolean;
 *  canStart: boolean;
 * }} props
 * @returns
 */
const Config = ({ onChangeConfig, onClickStart, isRunning, canStart }) => {
  const classes = useStyles();
  const config = useContext(ConfigContext);

  const updateAuthVersion = (event) => {
    const authVersion = event.target.value;
    switch (authVersion) {
      case AuthVersion.Auto:
      case AuthVersion.V2:
      case AuthVersion.V3:
        onChangeConfig({
          ...config,
          authVersion,
        });
        break;
      default:
        throw new Error(`Unknown authVersion: ${authVersion}`);
    }
  };

  const updateAuthProtocol = (event) => {
    const authenticationProtocol = event.target.value;
    switch (authenticationProtocol) {
      case AuthProtocol.md5:
      case AuthProtocol.sha:
      case AuthProtocol.sha224:
      case AuthProtocol.sha256:
      case AuthProtocol.sha384:
      case AuthProtocol.sha512:
        onChangeConfig({
          ...config,
          authenticationProtocol,
        });
        break;
      default:
        throw new Error(
          `Unknown authenticationProtocol: ${authenticationProtocol}`
        );
    }
  };

  const updatePrivacyProtocol = (event) => {
    const privacyProtocol = event.target.value;
    switch (privacyProtocol) {
      case PrivProtocol.aes:
      case PrivProtocol.aes256b:
      case PrivProtocol.aes256r:
      case PrivProtocol.des:
        onChangeConfig({
          ...config,
          privacyProtocol,
        });
        break;
      default:
        throw new Error(`Unknown privacyProtocol: ${privacyProtocol}`);
    }
  };

  return (
    <form className={classes.controlForm} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            size="small"
            required
            label="Device Hostname"
            variant="outlined"
            value={config.host}
            error={!config.host}
            disabled={isRunning}
            onChange={({ target: { value } }) => {
              onChangeConfig({
                ...config,
                host: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            size="small"
            select
            label="Authentication Version"
            variant="outlined"
            value={config.authVersion}
            onChange={updateAuthVersion}
            disabled={isRunning}
          >
            <MenuItem value={AuthVersion.Auto}>From Device</MenuItem>
            <MenuItem value={AuthVersion.V2}>Custom v2 Auth</MenuItem>
            <MenuItem value={AuthVersion.V3}>Custom v3 Auth</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <StartButton
            onClickStart={onClickStart}
            isRunning={isRunning}
            canStart={canStart}
          />
        </Grid>

        {config.authVersion === AuthVersion.V2 && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              required
              label="Community string"
              variant="outlined"
              value={config.communityString}
              error={!config.communityString}
              disabled={isRunning}
              onChange={({ target: { value } }) => {
                onChangeConfig({
                  ...config,
                  communityString: value,
                });
              }}
            />
          </Grid>
        )}

        {config.authVersion === AuthVersion.V3 && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                required
                label="Username"
                variant="outlined"
                value={config.username}
                error={!config.username}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    username: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                select
                label="Authentication Protocol"
                variant="outlined"
                value={config.authenticationProtocol}
                onChange={updateAuthProtocol}
                disabled={isRunning}
              >
                <MenuItem value={AuthProtocol.md5}>HMAC-MD5</MenuItem>
                <MenuItem value={AuthProtocol.sha}>HMAC-SHA-1</MenuItem>
                <MenuItem value={AuthProtocol.sha224}>HMAC-SHA-224</MenuItem>
                <MenuItem value={AuthProtocol.sha256}>HMAC-SHA-256</MenuItem>
                <MenuItem value={AuthProtocol.sha384}>HMAC-SHA-384</MenuItem>
                <MenuItem value={AuthProtocol.sha512}>HMAC-SHA-512</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                select
                variant="outlined"
                label="Privacy Protocol"
                value={config.privacyProtocol}
                onChange={updatePrivacyProtocol}
                disabled={isRunning}
              >
                <MenuItem value={PrivProtocol.des}>CBC-DES</MenuItem>
                <MenuItem value={PrivProtocol.aes}>CFB-AES-128</MenuItem>
                <MenuItem value={PrivProtocol.aes256b}>
                  CFB-AES-256 Blumenthal
                </MenuItem>
                <MenuItem value={PrivProtocol.aes256r}>
                  CFB-AES-256 Reeder
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                required
                label="Authentication Key"
                variant="outlined"
                value={config.authenticationKey}
                error={!config.authenticationKey}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    authenticationKey: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                required
                label="Privacy Key"
                variant="outlined"
                value={config.privacyKey}
                error={!config.privacyKey}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    privacyKey: value,
                  });
                }}
              />
            </Grid>
          </>
        )}

        {(config.authVersion === AuthVersion.V3 ||
          config.authVersion === AuthVersion.V2) && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Timeout"
                variant="outlined"
                value={config.timeout}
                error={!config.timeout}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    timeout: parseInt(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Retries"
                variant="outlined"
                value={config.retries}
                error={!config.retries}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    retries: parseInt(value),
                  });
                }}
              />
            </Grid>
            {/*
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Concurrency"
                variant="outlined"
                value={config.concurrency}
                error={!config.concurrency}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    concurrency: parseInt(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Max Get Size"
                variant="outlined"
                value={config.maxGetSize}
                error={!config.maxGetSize}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    maxGetSize: parseInt(value),
                  });
                }}
              />
            </Grid>
              */}

            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Max Get Bulk Size"
                variant="outlined"
                value={config.maxGetBulkSize}
                error={!config.maxGetBulkSize}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    maxGetBulkSize: parseInt(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                type="number"
                required
                label="Port"
                variant="outlined"
                value={config.port}
                error={!config.port}
                disabled={isRunning}
                onChange={({ target: { value } }) => {
                  onChangeConfig({
                    ...config,
                    port: parseInt(value),
                  });
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default Config;
