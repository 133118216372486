//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';
import { safelyGetRefData } from '../util/safelyGetRefData';

const SiaStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'SIA data',
      action: 'sia-data',
      data: {},
      onResponse: (data, log) => {
        if (Object.keys(data.refData).length === 0) {
          log.fatal(`No SIA data for hostname "${data.hostname}"`);
          return false;
        }

        log.info(`Found SIA data for hostname "${data.hostname}"`);

        const refData = safelyGetRefData(data);
        const ifNames = Object.keys(refData);

        if (ifNames.length === 1 && ifNames[0] === '*') {
          log.warn(
            'Device is using an old SIA data structure. A placeholder interface named * will be used in place of any specific interface. This placeholder interface should be considered a wildcard that will match any interface on the device.'
          );
        }

        let invalidInterfaces = [];

        for (const ifName of ifNames) {
          for (const propertyKey of Object.keys(refData[ifName])) {
            log.info(
              `"${ifName}"."${propertyKey}" = ${JSON.stringify(
                refData[ifName][propertyKey]
              )}`
            );
          }

          if (
            Object.keys(refData[ifName]).findIndex(
              (it) => it.toLowerCase() === 'CustomerName'.toLowerCase()
            ) === -1
          ) {
            invalidInterfaces.push(ifName);
          }
        }

        for (const ifName of invalidInterfaces) {
          log.fatal(`"${ifName}" is missing required property CustomerName`);
        }

        return invalidInterfaces.length === 0;
      },
    });
  }
);

export default SiaStep;
