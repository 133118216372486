//@ts-check
import { useRef, useState, useImperativeHandle } from 'react';

import { Status } from '../enums';

export const Suite = {
  /** @type {'Continue'} */ Continue: 'Continue',
  /** @type {'Success'} */ Success: 'Success',
  /** @type {'Fail'} */ Fail: 'Fail',
};

/**
 * @returns {Status}
 */
const getInitialStatus = () => Status.Idle;

/**
 * @typedef { keyof typeof Suite } Suite
 *
 * @param {React.Ref<any>} ref
 * @param {{
 *   onSuccess?: () => void;
 *   onFail?: () => void;
 * }} props
 * @param {{
 *   onSuccess?: Suite;
 *   onFail?: Suite;
 *   getStep: (props: {
 *     key: string;
 *     ref: React.Ref<any>;
 *     onSuccess?: () => void;
 *     onFail?: () => void;
 *   }) => JSX.Element;
 *  }[]} steps
 */
export const useSuite = (ref, { onSuccess, onFail }, steps) => {
  const [status, setStatus] = useState(getInitialStatus());

  const refs = [];
  const children = [];
  for (const step of steps) {
    // We should be able to call useRef inside a loop as long as the loop is the same on every call of the hook
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(null);
    const stepIndex = refs.length;
    refs.push(ref);
    children.push(
      step.getStep({
        key: String(stepIndex),
        ref: ref,
        onSuccess:
          status === Status.Running
            ? () => {
                if (!step.onSuccess) {
                  if (stepIndex + 1 < refs.length) {
                    step.onSuccess = Suite.Continue;
                  } else {
                    step.onSuccess = Suite.Success;
                  }
                }
                switch (step.onSuccess) {
                  case Suite.Continue:
                    refs[stepIndex + 1].current.startStep();
                    return;
                  case Suite.Success:
                    success();
                    return;
                  case Suite.Fail:
                    fail();
                    return;
                  default:
                    throw new Error('Unexpected next state in suite');
                }
              }
            : () => {},
        onFail:
          status === Status.Running
            ? () => {
                if (!step.onFail) {
                  step.onFail = Suite.Fail;
                }
                switch (step.onFail) {
                  case Suite.Continue:
                    refs[stepIndex + 1].current.startStep();
                    return;
                  case Suite.Success:
                    success();
                    return;
                  case Suite.Fail:
                    fail();
                    return;
                  default:
                    throw new Error('Unexpected next state in suite');
                }
              }
            : () => {},
      })
    );
  }

  const success = () => {
    setStatus(Status.Success);
    skipIfQueued();
    onSuccess && onSuccess();
  };

  const fail = () => {
    setStatus(Status.Fail);
    skipIfQueued();
    onFail && onFail();
  };

  const start = () => {
    setStatus(Status.Running);
    refs[0].current.startStep();
  };

  const enqueue = () => {
    for (const ref of refs) {
      ref.current.enqueue();
    }
  };

  const dequeue = () => {
    for (const ref of refs) {
      ref.current.dequeue();
    }
  };

  const skipIfQueued = () => {
    for (const ref of refs) {
      ref.current.skipIfQueued();
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useImperativeHandle(ref, () => ({
    startStep: start,
    enqueue,
    dequeue,
    skipIfQueued,
  }));

  return {
    steps: children,
    status,
    enqueue: enqueue,
    dequeue: dequeue,
    skipIfQueued: skipIfQueued,
    enqueueAndStart: () => {
      enqueue();
      start();
    },
    start: start,
  };
};
