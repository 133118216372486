//@ts-check
import React, { forwardRef, createContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useLocalStorage from 'use-local-storage';

import { useSimpleSuite } from './hooks/useSimpleSuite';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';

import OnboardedStep from './steps/OnboardedStep';
import { AuthProtocol, AuthVersion, PrivProtocol, Status } from './enums';
import Menu from './Menu';
import SnmpPollStep from './steps/SnmpPollStep';
import DnsLookupStep from './steps/DnsLookupStep';
import CustomSnmpPollStep from './steps/SnmpCustomPollStep';
import SiaStep from './steps/SiaStep';
import SiaCompareStep from './steps/SiaCompareStep';
import SiaStatisticsStep from './steps/SiaStatisticsStep';
import ExternalPortalConnectionsStep from "./steps/ExternalPortalConnectionsStep";
import ExternalPortalCustomersStep from './steps/ExternalPortalCustomersStep';
import StartButton from './StartButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  subHeading: {
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 * @returns {{
 *  host: string,
 *  authVersion: AuthVersion,
 *  communityString: string,
 *  username: string,
 *  authenticationKey: string,
 *  authenticationProtocol: AuthProtocol,
 *  privacyKey: string,
 *  privacyProtocol: PrivProtocol,
 *  timeout: number,
 *  retries: number,
 *  port: number,
 //*  concurrency: number,
 //*  maxGetSize: number,
 *  maxGetBulkSize: number,
 * }}
 */
export const getInitialContext = () => ({
  host: "",
  authVersion: AuthVersion.Auto,
  communityString: "",
  username: "",
  authenticationKey: "",
  authenticationProtocol: AuthProtocol.sha,
  privacyKey: "",
  privacyProtocol: PrivProtocol.aes,
  timeout: 5000,
  retries: 1,
  port: 161,
  //concurrency: 1, //This is SNMP BE specific and not used by net-snmp
  //maxGetSize: 50, //This is SNMP BE specific and not used by net-snmp
  maxGetBulkSize: 50, //This is used by net-snmp for subtree requests
});

export const ConfigContext = createContext(getInitialContext());

const DebugDevice = forwardRef((_, ref) => {
  const classes = useStyles();
  const [config, setConfig] = useLocalStorage(
    'debug-device-config',
    getInitialContext()
  );
  const suite = useSimpleSuite(ref, {}, [
    (props) => <OnboardedStep {...props} />,
    (props) => <DnsLookupStep {...props} />,
    (props) => <SnmpPollStep {...props} />,
    (props) => <SiaStep {...props} />,
  ]);
  const externalPortalSuite = useSimpleSuite(ref, {}, [
    (props) => <ExternalPortalConnectionsStep {...props} />,
    (props) => <ExternalPortalCustomersStep {...props} />,
  ]);

  if (!config.timeout || !config.retries || !config.maxGetBulkSize) {
    setConfig(getInitialContext());
  }

  const canStart = () => {
    if (!config.host) {
      return false;
    }
    switch (config.authVersion) {
      case AuthVersion.Auto:
        return true;
      case AuthVersion.V2:
        return !!config.communityString;
      case AuthVersion.V3:
        return (
          !!config.username &&
          !!config.authenticationKey &&
          !!config.authenticationProtocol &&
          !!config.privacyKey &&
          !!config.privacyProtocol
        );
      default:
        return false;
    }
  };

  return (
    <ConfigContext.Provider value={config}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Grid item xs={12}>
          <Menu
            isRunning={suite.status === Status.Running}
            onChangeConfig={setConfig}
            onClickStart={suite.enqueueAndStart}
            canStart={canStart()}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Typography className={classes.subHeading}>
          Core Device Checks
        </Typography>
      </Grid>
      <div className={classes.root}>
        {suite.status === Status.Running && <LinearProgress />}
        {suite.status === Status.Fail && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            The selected device is not working correctly. See below for what
            went wrong.
          </Alert>
        )}
        {suite.status === Status.Success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            The selected device is working as it should.
          </Alert>
        )}
        {suite.steps}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 16 }}
        >
          <Grid item xs={10}>
            <Typography className={classes.subHeading}>
              External Portal Checks
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <StartButton
              onClickStart={externalPortalSuite.enqueueAndStart}
              isRunning={externalPortalSuite.status === Status.Running}
              canStart={canStart()}
            />
          </Grid>
        </Grid>

        {externalPortalSuite.status === Status.Running && <LinearProgress />}
        {externalPortalSuite.status === Status.Fail && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We could not find any related data configured in the external portal
            administration.
          </Alert>
        )}
        {externalPortalSuite.status === Status.Success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Data found in external portal administration.
          </Alert>
        )}
        {externalPortalSuite.steps}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 16 }}
        >
          <Typography className={classes.subHeading}>Manual steps</Typography>
        </Grid>

        <CustomSnmpPollStep />
        <SiaCompareStep />
        <SiaStatisticsStep />
        {/* <CheckAPLogsStep /> */}
      </div>
    </ConfigContext.Provider>
  );
});

export default DebugDevice;
