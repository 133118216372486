//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const ExternalPortalCustomersStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: "Customer Check",
      action: "check-ext-customer",
      data: {},
      onResponse: (data, log) => {
        console.log(data);
        if (data.error) {
          log.fatal(`There was an error checking the external portal!`);
          log.fatal(data.error);
          return false;
        }

        if (data.found === 0) {
          log.fatal(`No Customer found for device!`);
          return false;
        }

        log.info(`Found ${data.customers.length} customers.`);

        for (const customer of data.customers) {
          log.info(`Found: "${customer.name}"`);

          customer.reportGroups.forEach((group) => {
            log.info(`Report Group: "${group}"`);
          });
        }
      },
    });
  }
);

export default ExternalPortalCustomersStep;
