//@ts-check
import { forwardRef } from 'react';

import { useActionStep } from '../hooks/useActionStep';

const ExternalPortalConnectionsStep = forwardRef(
  /**
   * @param {{
   *  onSuccess?: () => void,
   *  onFail?: () => void,
   * }} props
   * @param {React.Ref<any>} ref
   */
  ({ onSuccess, onFail }, ref) => {
    return useActionStep(ref, {
      onSuccess,
      onFail,
      name: 'Connections Check',
      action: 'check-ext-connections',
      data: {},
      onResponse: (data, log) => {
        if (data.error) {
          log.fatal(`There was an error checking the external portal!`);
          log.fatal(data.error);
          return false;
        }

        if (data.found === 0) {
          log.fatal(`No Connections found for device!`);
          return false;
        }

        log.info(`Found ${data.connections.length} connections for device`);

        for (const connection of data.connections) {
          log.info(`Found connection ${connection.id}, with type ${connection.type}`);
        }
      },
    });
  }
);

export default ExternalPortalConnectionsStep;
